body {
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

section {
  padding: 2rem;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  margin: 1rem 0;
  padding-left: 20%;
  padding-right: 20%;
}

section h1 {
  font-size: 2.5rem;
}

h1, h2 {
  margin-top: 0;
  color: #f97044;
  font-weight: 400;
}
h3{
  font-weight: 400;
}

.link {
  color: #ce9696; /* Dark Gray for all link states */
  font-weight: bold;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.number {
  color: #f97044;
  font-size: 1.6rem;
  font-weight: 100;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  font-weight: 100;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

form input, form textarea {
  width: 100%;
  margin-top: 10px;
}

form textarea {
  line-height: 5rem;
}

label {
  margin-bottom: 1rem;
  font-weight: 100;
}

input, textarea {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

button {
  padding: 0.75rem;
  background: linear-gradient(90deg, #6f7479, #1b2122);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 100;
  transition: background 0.3s ease;
}

button:hover {
  background: linear-gradient(90deg, #0056b3, #0097fc);
}

.hero-section {
  background: url('/public/hero.jpg') no-repeat center center/cover;
  color: #fff;
  position: relative;
  height: 60vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 2rem;
}

.hero-card {
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 8px;
  max-width: 500px;
  text-align: left;
  animation: fadeIn 2s;
}

.hero-card h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
}

.hero-card p {
  font-size: 1.2rem;
  line-height: 1.8;
  font-weight: 100;
}

.services-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-card {
  background: rgba(0, 0, 0, 0.7);
  border: 2px solid #f97044;
  padding-top: 2.5rem;
  padding: 2.5rem .8rem 2.5rem .8rem;
  margin: 1rem;
  flex: 1;
  max-width: 30%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-card h2 {
  color: #f97044;
  margin-bottom: 1rem;
}

.service-card a, .service-card a:visited{
  color: #f97044;
  margin-bottom: 1rem;
}

a,a:visited{
  color: #f97044;
}

.service-card p {
  font-size: 1rem;
  font-weight: 400;
  color: white;
}

.scroll-adjust {
  padding-top: 6rem; /* Adjust this value to match your header height */
}

.contact-us h1 {
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive Design */
@media (max-width: 1200px) {
  section {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: 992px) {
  section {
    padding-left: 10%;
    padding-right: 10%;
  }
}


@media (max-width: 768px) {
  section {
    padding-left: 5%;
    padding-right: 5%;
  }

  .hero-section {
    height: 40vh;
    padding: 1rem;
  }

  .hero-card {
    padding: 1rem;
    max-width: 90%;
  }

  .hero-card h1 {
    font-size: 2.5rem;
  }

  .hero-card p {
    font-size: 1rem;
    line-height: 1.4;
  }

  .services-container {
    flex-direction: column;
  }

  .service-card {
    max-width: 100%;
    margin: 1rem 0;
  }

  .service-card h2 {
    font-size: 1.5rem;
  }

  .service-card p {
    font-size: 0.9rem;
  }

  section h1 {
    font-size: 2rem;
  }

  .number {
    font-size: 1.4rem;
  }

  p {
    font-size: 1rem;
  }

  #contact-us iframe{
    width: 100%;
  }
}

@media (max-width: 576px) {

  .hero-card h1 {
    font-size: 2rem;
  }

  .hero-card p {
    font-size: 0.9rem;
  }

  section {
    padding: 1rem;
  }
}

@media (max-width: 414px) {
  .hero-section {
    height: 35vh;
    padding: 1rem;
  }
}

@media (max-width: 375px) {
  .hero-section {
    height: 45vh;
    padding: 1rem;
  }
}