/* Navbar.css */

.navbar {
  background: #333333; /* Dark Gray */
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  padding: 0.5rem 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  height: 80px;
  filter: invert(100%) brightness(100%) saturate(0);
}

.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  margin-right: 5%;
  padding: 0;
}

.nav-item {
  margin: 0 1rem;
}

.nav-link {
  color: #d7d7d7; /* Light text for contrast */
  font-weight: bold;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.nav-link:visited {
  color: #d7d7d7; /* Ensures visited links are also light */
}

.nav-link:hover {
  color: #f97044; /* Yellow for hover effect */
}

/* Responsive Design */
@media (max-width: 768px) {
  .nav-menu {
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: #333333; /* Dark Gray */
    position: fixed;
    top: 80px; /* Adjust according to your navbar height */
    left: 0;
    right: 0;
    display: none; /* Hide the menu by default */
  }

  .nav-item {
    margin: 1rem 0;
  }

  .nav-menu.active {
    display: flex; /* Show the menu when active */
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    margin-right: 45px;
  }

  .menu-icon div {
    width: 25px;
    height: 3px;
    background-color: #ffffff; /* Light color for the hamburger menu */
    margin: 4px 0;
    transition: 0.4s;
  }

  .change .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}

/* Hide the menu icon on larger screens */
@media (min-width: 769px) {
  .menu-icon {
    display: none;
  }
}