/* Footer.css */

.footer {
    background: #414141;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }

  .footer-container p{
    margin:auto;
  }
  
  .footer-menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
    margin: 0 1rem;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #ffeb3b;
  }